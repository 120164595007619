/**
 * Direttiva che crea il footer applicativo
 */
export interface IFooterDirectiveScope extends ng.IScope {
	page: any; 
	globalApplicationData: any;
	bgacademyApplicationData: any;
	loggedUsr: any;
	footerVisible: boolean;
}
angular.module('app').directive("bgfooter", ($window, $state) => {
	return {
		link: link,
		replace: true,
		restrict: 'AE',
		templateUrl: 'app/shared/footer/footer.html'
	};
	// Quando la larghezza della finestra è maggiore di 600px pulsante del menu si nasconde e, se è rimasto aperto, rimane l'icona Close. Quindi dopo i 600px reimposto l'icona di default
	function link($scope: IFooterDirectiveScope, element: any, attrs: ng.IAttributes) {
		// Tiene traccia della visiblità del footer
		$scope.footerVisible = true;

		// All'apertura della modalità pdf, nascondo il footer
		$scope.$on('pdfModeOpened', (event: any, data: any) => {
			if(data === true){
				$scope.footerVisible =  false;
			}
		});

		// Alla chiusura della modalità pdf, apro il footer
		$scope.$on('pdfModeClosed', (event: any, data: any) => {
			if(data === true){
				$scope.footerVisible =  true;
			}
		});

	}
});